import * as ACTIONS from "./questions-actions";
import getQuestions from "./getQuestions";
import jsonLogic from "json-logic-js";

const questionsReducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SAVE_QUESTION_REPLY:
			const { reply, id } = action.payload;

			const question = state.byId[id];
			let nextId = question.next;

			if (question.fn) nextId = jsonLogic.apply(question.fn, { reply });
			return {
				...state,
				byId: {
					...state.byId,
					[id]: { ...question, reply },
				},
				currentQuestion: nextId ? state.byId[nextId] : null,
			};

		case ACTIONS.MAKE_QUESTIONS_LIST:
			const questions = getQuestions(state);

			return {
				...state,
				questions,
			};

		case ACTIONS.ADD_PREVIOUS_QUESTIONS:
			return {
				...state,
				previousQuestions: [...state.previousQuestions, action.payload],
			};

		case ACTIONS.REMOVE_PREVIOUS_QUESTIONS:
			const { byId, previousQuestions } = state;
			const previousQuestion = previousQuestions.pop();

			return {
				...state,
				previousQuestions: [...previousQuestions],
				currentQuestion: byId[previousQuestion],
			};

		case ACTIONS.SAVE_API_RESPONSE:
			return {
				...state,
				apiResponse: action.payload,
			};

		default:
			return state;
	}
};

export default questionsReducer;
