import { lazy, Suspense, useEffect } from "react";
import Cookies from "js-cookie";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { LOGIN, DASHBOARD } from "./constants/routes";
import { showFooter } from "./configs/urlBasedConfig";

// Antd
import "antd/es/message/style/css";
import "antd/es/button/style/css";

// Components
import Routes from "./Routes";
import Loader from "./components/Loader";
const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const Login = lazy(() => import("./pages/Login"));

const App = () => {
	useEffect(() => {
		if (Cookies.get("ACCESS_TOKEN")) {
			if (window.location.pathname === LOGIN) window.location.assign(DASHBOARD);
		} else if (window.location.pathname !== LOGIN) {
			window.location.assign(LOGIN);
		}
	}, []);

	return (
		<BrowserRouter>
			<Suspense fallback={<Loader />}>
				<Switch>
					<Route exact path={LOGIN} component={Login} />
					<Route
						render={props => (
							<div className="flex flex-col w-full min-h-screen">
								<Header />
								<div className="flex-1 w-full mx-auto max-w-screen-2xl">
									<Suspense fallback={<Loader />}>
										<main className="p-4 sm:p-8 md:p-12">
											<Routes />
										</main>
									</Suspense>
								</div>

								{showFooter.has(props.location.pathname) && <Footer />}
							</div>
						)}
					/>
				</Switch>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
