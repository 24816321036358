import { createContext, useReducer } from "react";
import questionsReducer from "./questions-reducer";
import * as ACTIONS from "./questions-actions";
import { normalize, schema } from "normalizr";
import getQuestions from "./getQuestions";
import data from "../configs/form.json";
// import getQueryStrings from "./getQueryStrings";

const question = new schema.Entity("questions");
const mySchema = { questions: [question] };
const { entities } = normalize(data, mySchema);

const byId = entities.questions;
const currentQuestion = data.questions[0];
const firstQuestion = currentQuestion.id;
const questionsList = getQuestions({ byId, firstQuestion });

// const queryStrings = getQueryStrings();

const initialState = {
	byId,
	currentQuestion,
	questions: questionsList,
	firstQuestion,
	previousQuestions: [],
	apiResponse: {},
	queryStrings: {},
};

export const QuestionsContext = createContext();

const QuestionsContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(questionsReducer, initialState);

	const saveReply = (id, reply) =>
		dispatch({ type: ACTIONS.SAVE_QUESTION_REPLY, payload: { id, reply } });

	const makeQuestionsList = () => dispatch({ type: ACTIONS.MAKE_QUESTIONS_LIST });

	const addToPreviousQuestion = id =>
		dispatch({ type: ACTIONS.ADD_PREVIOUS_QUESTIONS, payload: id });

	const removeFromPreviousQuestion = () => {
		dispatch({ type: ACTIONS.REMOVE_PREVIOUS_QUESTIONS });
	};

	const saveApiResponse = data => dispatch({ type: ACTIONS.SAVE_API_RESPONSE, payload: data });

	return (
		<QuestionsContext.Provider
			value={{
				apiResponse: state.apiResponse,
				currentQuestion: state.currentQuestion,
				firstQuestion: state.firstQuestion,
				questions: state.questions,
				byId: state.byId,
				previousQuestions: state.previousQuestions,
				queryStrings: state.queryStrings,
				addToPreviousQuestion,
				makeQuestionsList,
				removeFromPreviousQuestion,
				saveApiResponse,
				saveReply,
			}}>
			{children}
		</QuestionsContext.Provider>
	);
};

export default QuestionsContextProvider;
