import jsonLogic from "json-logic-js";

const getQuestions = state => {
	const { byId, firstQuestion } = state;
	let i = firstQuestion;
	const _questions = { [byId[i].id]: byId[i] };

	while (byId[i]) {
		let nextId = "";
		const currQuestion = byId[i];

		if (currQuestion.fn) nextId = jsonLogic.apply(currQuestion.fn, { reply: currQuestion.reply });
		else nextId = currQuestion.next;

		if (nextId) _questions[nextId] = byId[nextId];

		i = nextId;
	}

	return _questions;
};

export default getQuestions;
