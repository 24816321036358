import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import * as ROUTES from "./constants/routes";
import QuestionsContextProvider from "./context/questions-store";

// Components
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Details = lazy(() => import("./pages/Details"));
const Earnings = lazy(() => import("./pages/Earnings"));
const Escalations = lazy(() => import("./pages/Escalations"));
const Form = lazy(() => import("./pages/BasicForm"));
const Patients = lazy(() => import("./pages/Patients"));

const Routes = () => (
	<Switch>
		<Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
		<Route exact path={ROUTES.DETAILS} component={Details} />
		<Route exact path={ROUTES.EARNINGS} component={Earnings} />
		<Route exact path={ROUTES.ESCALATIONS} component={Escalations} />
		<Route exact path={ROUTES.FORM}>
			<QuestionsContextProvider>
				<Form />
			</QuestionsContextProvider>
		</Route>
		<Route exact path={ROUTES.PATIENTS} component={Patients} />
		<Redirect to={ROUTES.DASHBOARD} />
	</Switch>
);

export default Routes;
